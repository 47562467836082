var PROXY_VIN_PREFIX = 'SER';
var COMPONENT_VIN_PREFIX = 'COM';
/** The padding character used for surrogate (i.e. proxy and component) VINs. */
var SURROGATE_VIN_CHAR = '_';
/** The length of a standard JCB serial number (excludes component serial numbers which are 8 characters long). */
var SERIAL_LENGTH_STD = 7;
/** The maximum allowed length of a JCB serial number. */
var SERIAL_LENGTH_MAX = 8;
/** Checks if supplied VIN is a proxy VIN (i.e. starts with the characters SER_). */
function isProxyVin(vin) {
    return vin.startsWith(PROXY_VIN_PREFIX + SURROGATE_VIN_CHAR);
}
/** Checks if supplied VIN is a component VIN (i.e. starts with the characters COM_). */
function isComponentVin(vin) {
    return vin.startsWith(COMPONENT_VIN_PREFIX + SURROGATE_VIN_CHAR);
}
/** Checks if supplied VIN is a surrogate (i.e. is a proxy or component VIN). */
function isSurrogateVin(vin) {
    return isProxyVin(vin) || isComponentVin(vin);
}
/** Returns the serial number from a VIN, or returns the VIN if it cannot be converted to a valid serial number. */
export function transformVin(vin) {
    if (!isSurrogateVin(vin) || vin.length < SERIAL_LENGTH_STD) {
        return vin;
    }
    var serial = isComponentVin(vin)
        ? vin.substring(vin.length - SERIAL_LENGTH_MAX)
        : vin.substring(vin.length - SERIAL_LENGTH_STD);
    if (serial.includes(SURROGATE_VIN_CHAR)) {
        return vin;
    }
    return serial;
}
