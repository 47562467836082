var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DefinitionList, Panel } from "@cognitranlimited/itis-web";
import { useGetFaultsQuery, useGetQuery } from "./livelinkApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { useLivelinkContext } from "./livelinkContext";
import { FaultCodeTable } from "./FaultCodeTable";
import './LivelinkDataPanel.scss';
import { LivelinkDate } from "./LivelinkDate";
import { LivelinkDataPanelSkeleton } from './LivelinkDataPanelSkeleton';
export var LivelinkDataPanel = function (_a) {
    var vehicle = _a.vehicle;
    var strings = useLivelinkContext().strings.livelink;
    var vin = vehicle.ref === 'vin' && vehicle.vin;
    var _b = useGetQuery(vin ? vin : skipToken), livelink = _b.data, error = _b.error, isLoading = _b.isLoading;
    var faults = useGetFaultsQuery(vin ? vin : skipToken).data;
    if (isLoading) {
        return _jsx(LivelinkDataPanelSkeleton, {});
    }
    if (error) {
        if ("status" in error && error.status === 404) {
            return _jsx(Panel, { children: _jsx(Panel.Message, { text: strings.errorNotFound }) });
        }
        else {
            return _jsx(Panel, { children: _jsx(Panel.Message, { text: strings.errorOther }) });
        }
    }
    function getGlobalProperty(key) {
        var property = global.iwaProperties[key];
        if (!property) {
            throw new Error("Global property not set: ".concat(key));
        }
        return property;
    }
    return _jsxs(_Fragment, { children: [((livelink === null || livelink === void 0 ? void 0 : livelink.longitude) && livelink.latitude) &&
                _jsx(Panel, { children: _jsxs(Panel.Content, { children: [_jsxs("div", __assign({ className: "LivelinkDataPanel-mapImage" }, { children: [_jsx("figcaption", __assign({ className: "LivelinkDataPanel-mapImage-blurb-text" }, { children: strings.imageBlurb })), _jsxs("figure", __assign({ "aria-labelledby": "LivelinkDataPanel-mapImageCaption" }, { children: [_jsx("a", __assign({ href: "https://www.google.com/maps/search/?api=1&query=" + livelink.latitude + ',' + livelink.longitude }, { children: _jsx("img", { src: getGlobalProperty("itisUrl") + "/service/livelink/google/map?zoom=10&size=495x330&longLat=" + livelink.latitude + ',' + livelink.longitude }) })), _jsxs("figcaption", { children: [strings.latitude, ": ", livelink.longitude, ", ", strings.longitude, ": ", livelink.longitude] }), (livelink === null || livelink === void 0 ? void 0 : livelink.locationDate) && _jsxs("figcaption", { children: [strings.locationAsOf, " ", _jsx(LivelinkDate, { date: livelink.locationDate })] })] }))] })), _jsx(DefinitionList, { definitions: [
                                    { name: strings.operatingHours, value: (livelink === null || livelink === void 0 ? void 0 : livelink.operatingHours) === undefined ? "Unknown" : "".concat(String(livelink.operatingHours), " ").concat(strings.hours) },
                                    { name: strings.idle, value: (livelink === null || livelink === void 0 ? void 0 : livelink.idle) === undefined ? "Unknown" : "".concat(String(livelink.idle), " ").concat(strings.hours) },
                                    { name: strings.fuelLevel, value: (livelink === null || livelink === void 0 ? void 0 : livelink.fuelLevel) === undefined ? "Unknown" : livelink.fuelLevel + "%" },
                                    { name: strings.defRemaining, value: (livelink === null || livelink === void 0 ? void 0 : livelink.defRemaining) === undefined ? "Unknown" : livelink.defRemaining + "%" },
                                ] })] }) }), (faults !== undefined && faults.FaultCode.length > 0) &&
                _jsxs(Panel, __assign({ className: "LivelinkDataPanel-faultsPanel" }, { children: [_jsx(Panel.Header, { title: "".concat(strings.faultCodeFound, " (").concat(faults.FaultCode.length, ")") }), _jsx(Panel.Content, { children: _jsx(FaultCodeTable, { faultCodes: faults.FaultCode, vin: vin ? vin : "" }) })] }))] });
};
