import { apiBaseQuery } from '@cognitranlimited/itis-web';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
export var termsApi = createApi({
    reducerPath: 'termsApi',
    baseQuery: apiBaseQuery(),
    endpoints: function (builder) { return ({
        getDocumentInfo: builder.query({
            query: function (documentId) { return "/documents/".concat(documentId, "/information"); }
        }),
        getLegalDocumentContent: builder.query({
            query: function (legalDocumentType) { return ({
                url: "/documents/legal/custom/".concat(legalDocumentType, "/content"),
                responseHandler: function (response) { return response.text(); }
            }); }
        })
    }); }
});
