var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, HtmlView, Panel, PanelGrid } from '@cognitranlimited/itis-web';
import './TermsCoverPage.scss';
import { useTermsModuleContext } from './termsContext';
export var TermsCoverPage = function (_a) {
    var legalDocumentType = _a.legalDocumentType, onAgree = _a.onAgree, onDisagree = _a.onDisagree, children = _a.children;
    var strings = useTermsModuleContext().strings.jcbTerms;
    return _jsx(PanelGrid, __assign({ className: "TermsCoverPage" }, { children: _jsxs(Panel, { children: [_jsx("div", __assign({ className: "TermsCoverPage-header" }, { children: _jsx("h3", { children: legalDocumentType === 'PERKINS_TERMS_AND_CONDITIONS' ? strings.perkinsTitle : strings.cumminsTitle }) })), _jsx(Panel.Content, { children: _jsx(HtmlView, __assign({ className: "TermsCoverPage-content" }, { children: children })) }), _jsxs("div", __assign({ className: "TermsCoverPage-buttons" }, { children: [_jsx(Button, __assign({ onClick: onDisagree }, { children: strings.disagreeButton })), _jsx(Button, __assign({ onClick: onAgree }, { children: strings.agreeButton }))] }))] }) }));
};
