import { createSelector, createSlice } from '@reduxjs/toolkit';
var termsInitialState = {
    agreedTerms: {
        CUMMINS_TERMS_AND_CONDITIONS: false,
        PERKINS_TERMS_AND_CONDITIONS: false
    }
};
export var termsSlice = createSlice({
    name: 'terms',
    initialState: termsInitialState,
    reducers: {
        agreeTerms: function (state, action) {
            state.agreedTerms[action.payload] = true;
        },
        resetTermsAgreement: function (state) {
            state.agreedTerms = {
                CUMMINS_TERMS_AND_CONDITIONS: false,
                PERKINS_TERMS_AND_CONDITIONS: false
            };
        }
    }
});
export var selectTermsAgreed = createSelector([
    function (state) { return state.terms.agreedTerms; },
    function (_state, legalDocumentType) { return legalDocumentType; }
], function (agreedTerms, legalDocumentType) { return agreedTerms[legalDocumentType]; });
