var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Link } from "@cognitranlimited/itis-web";
import { Table } from "@cognitranlimited/itis-web";
import { LivelinkDate } from "./LivelinkDate";
export var dtcCodeRegex = /^(.*)\(([A-Z0-9]+[- \d]{1,2}[A-Z0-9]+)\)(.*?)$/;
function searchLinkToFaultCode(codeDescription, vin) {
    var matches = dtcCodeRegex.exec(codeDescription);
    if (matches != null) {
        return _jsxs("span", { children: [matches[1], _jsxs(Link, __assign({ href: "/vehicles/".concat(vin, "/search?query=").concat(matches[2], "&searchContents=true&searchDescriptions=true&searchTitles=true") }, { children: ["[", matches[2], "]"] })), matches[3]] });
    }
    return codeDescription;
}
export var FaultTableRow = function (_a) {
    var rowProps = _a.rowProps, isCompactTable = _a.isCompactTable, faultCode = _a.faultCode, vin = _a.vin;
    return _jsxs(Table.Row, __assign({ rowProps: rowProps }, { children: [_jsx(Table.TextCell, { children: searchLinkToFaultCode(faultCode.CodeDescription, vin) }), _jsx(Table.TextCell, { children: faultCode.CodeSeverity }), _jsx(Table.TextCell, __assign({ wrap: false }, { children: _jsx(LivelinkDate, { date: faultCode.DateTime }) }))] }));
};
