import { createApi, } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from "@cognitranlimited/itis-web";
function roundNumber(val, fixed) {
    return val !== undefined ? Number(Number(val).toFixed(fixed)) : undefined;
}
function roundStringNumber(val, fixed) {
    return val !== undefined ? Number(val).toFixed(fixed) : undefined;
}
export var livelinkApi = createApi({
    reducerPath: 'livelinkApi',
    baseQuery: apiBaseQuery(),
    tagTypes: ['Livelink'],
    endpoints: function (builder) { return ({
        get: builder.query({
            query: function (vin) { return "/service/livelink/".concat(vin); },
            extraOptions: {
                target: 'itis'
            },
            transformResponse: function (response) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                return ({
                    longitude: (_b = (_a = response.Equipment[0]) === null || _a === void 0 ? void 0 : _a.Location) === null || _b === void 0 ? void 0 : _b.Longitude,
                    latitude: (_d = (_c = response.Equipment[0]) === null || _c === void 0 ? void 0 : _c.Location) === null || _d === void 0 ? void 0 : _d.Latitude,
                    locationDate: (_f = (_e = response.Equipment[0]) === null || _e === void 0 ? void 0 : _e.Location) === null || _f === void 0 ? void 0 : _f.DateTime,
                    operatingHours: roundNumber((_h = (_g = response.Equipment[0]) === null || _g === void 0 ? void 0 : _g.CumulativeOperatingHours) === null || _h === void 0 ? void 0 : _h.Hour, 1),
                    idle: roundNumber((_k = (_j = response.Equipment[0]) === null || _j === void 0 ? void 0 : _j.CumulativeIdleHours) === null || _k === void 0 ? void 0 : _k.Hour, 1),
                    fuelLevel: roundStringNumber((_m = (_l = response.Equipment[0]) === null || _l === void 0 ? void 0 : _l.FuelRemaining) === null || _m === void 0 ? void 0 : _m.Percent, 0),
                    defRemaining: roundStringNumber((_p = (_o = response.Equipment[0]) === null || _o === void 0 ? void 0 : _o.DEFRemaining) === null || _p === void 0 ? void 0 : _p.Percent, 0)
                });
            },
        }),
        getFaults: builder.query({
            query: function (vin) { return "service/livelink/".concat(vin, "/Faults"); },
            extraOptions: {
                target: 'itis'
            },
            transformResponse: function (response) { return ({
                FaultCode: response.FaultCode.sort(function (f1, f2) { return new Date(f2.DateTime).getTime() - new Date(f1.DateTime).getTime(); })
            }); }
        })
    }); }
});
export var useGetQuery = livelinkApi.useGetQuery, useGetFaultsQuery = livelinkApi.useGetFaultsQuery;
