var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DateTime } from "luxon";
export var LivelinkDate = function (_a) {
    var date = _a.date;
    function formatDate(dateStr) {
        return DateTime.fromISO(dateStr).toFormat("dd/MM/yyyy hh:mm a");
    }
    return _jsx("time", __assign({ dateTime: date }, { children: formatDate(date) }));
};
