import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Table } from "@cognitranlimited/itis-web";
import { FaultTableRow, } from "./FaultTableRow";
import { useLivelinkContext } from "./livelinkContext";
export var FaultCodeTable = function (_a) {
    var faultCodes = _a.faultCodes, vin = _a.vin;
    var strings = useLivelinkContext().strings.livelink;
    var columns = useMemo(function () { return ([
        { id: 'description', title: strings.description },
        { id: 'severity', title: strings.severity },
        { id: 'date', title: strings.date },
    ]); }, [strings.code, strings.date, strings.date, strings.description]);
    function getRow(index) {
        var faultCode = faultCodes[index];
        return {
            id: faultCode.CodeDescription,
            component: FaultTableRow,
            props: {
                faultCode: faultCode,
                vin: vin
            }
        };
    }
    return _jsx(Table, { columns: columns, rowCount: faultCodes.length, getRow: getRow });
};
