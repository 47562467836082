var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Skeleton from '@mui/material/Skeleton';
import './LivelinkDataPanelSkeleton.scss';
import { DefinitionList, Panel } from '@cognitranlimited/itis-web';
import { useLivelinkContext } from './livelinkContext';
var textHeight = 24;
export var LivelinkDataPanelSkeleton = function () {
    var strings = useLivelinkContext().strings.livelink;
    return _jsx("div", __assign({ role: "progressbar" }, { children: _jsx(Panel, { children: _jsxs(Panel.Content, { children: [_jsxs("div", __assign({ className: "LivelinkDataPanelSkeleton-imageContainer" }, { children: [_jsx(Skeleton, { width: 300, height: textHeight }), _jsx(Skeleton, { className: "LivelinkDataPanelSkeleton-image", variant: "rectangular", width: "100%", height: 330 }), _jsx(Skeleton, { width: 300, height: textHeight }), _jsx(Skeleton, { width: 250, height: textHeight })] })), _jsx(DefinitionList, { definitions: [
                            { name: strings.operatingHours, value: _jsx(Skeleton, { width: 100 }) },
                            { name: strings.idle, value: _jsx(Skeleton, { width: 160 }) },
                            { name: strings.fuelLevel, value: _jsx(Skeleton, { width: 80 }) },
                            { name: strings.defRemaining, value: _jsx(Skeleton, { width: 120 }) }
                        ] })] }) }) }));
};
