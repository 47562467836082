var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { termsApi } from './termsApi';
import { TermsCoverPage } from './TermsCoverPage';
import { selectTermsAgreed, termsSlice } from './termsSlice';
import { termsModuleId } from './termsContext';
import { middleware, reducers } from './termsStore';
import { getStringsFileLanguage } from '../strings';
export function termsModule() {
    return {
        id: termsModuleId,
        reducers: reducers,
        middleware: middleware,
        hooks: function (_a) {
            var dispatch = _a.dispatch, getState = _a.getState, notify = _a.notify, strings = _a.strings;
            return ({
                documentCoverPage: function (document) {
                    return __awaiter(this, void 0, void 0, function () {
                        var linkType_1, legalDocumentType_1, content_1, _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _b.trys.push([0, 3, , 4]);
                                    if (document.documentTypeContentType !== 'LINK') {
                                        return [2 /*return*/, null];
                                    }
                                    return [4 /*yield*/, dispatch(termsApi.endpoints.getDocumentInfo.initiate(document.id)).unwrap()];
                                case 1:
                                    linkType_1 = (_b.sent()).linkType;
                                    legalDocumentType_1 = (function () {
                                        if (linkType_1 === 'cummins') {
                                            return 'CUMMINS_TERMS_AND_CONDITIONS';
                                        }
                                        if (linkType_1 === 'perkins') {
                                            return 'PERKINS_TERMS_AND_CONDITIONS';
                                        }
                                    })();
                                    if (!legalDocumentType_1 || selectTermsAgreed(getState(), legalDocumentType_1)) {
                                        return [2 /*return*/, null];
                                    }
                                    return [4 /*yield*/, dispatch(termsApi.endpoints.getLegalDocumentContent.initiate(legalDocumentType_1)).unwrap()];
                                case 2:
                                    content_1 = _b.sent();
                                    return [2 /*return*/, function (onClose) { return _jsx(TermsCoverPage, __assign({ legalDocumentType: legalDocumentType_1, onAgree: function () {
                                                dispatch(termsSlice.actions.agreeTerms(legalDocumentType_1));
                                                onClose();
                                            }, onDisagree: function () { return onClose(true); } }, { children: content_1 })); }];
                                case 3:
                                    _a = _b.sent();
                                    notify(strings.document.coverPageError);
                                    return [2 /*return*/, null];
                                case 4: return [2 /*return*/];
                            }
                        });
                    });
                },
                logOut: function () {
                    return __awaiter(this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            dispatch(termsSlice.actions.resetTermsAgreement());
                            dispatch(termsApi.util.resetApiState());
                            return [2 /*return*/];
                        });
                    });
                }
            });
        },
        strings: function (language) {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 4]);
                            return [4 /*yield*/, import("./strings/".concat(getStringsFileLanguage(language), ".strings"))];
                        case 1: return [2 /*return*/, (_b.sent()).default];
                        case 2:
                            _a = _b.sent();
                            return [4 /*yield*/, import('./strings/en.strings')];
                        case 3: return [2 /*return*/, (_b.sent()).default];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
    };
}
